

import { React, useEffect, useState, useRef } from 'react';
import { Alert, Button, Pressable, StyleSheet, Text, View } from 'react-native';
import { EasyButton, LayersControl, MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import * as L from "leaflet";
//import L from "leaflet";
//import "leaflet/dist/leaflet.css"; // breaks markers
import { Icon } from "leaflet";
import Modal from 'react-bootstrap/Modal';
import useSWR from "swr";
import "./App.css";
import "leaflet-easybutton/src/easy-button.js"
import "leaflet-easybutton/src/easy-button.css";
//import "font-awesome/css/font-awesome.min.css";


//const api = "https://172.16.21.90:9000/devices";
const api = "https://map.netnix.ee:9000/devices";
//const api = "http://172.16.21.90:9000/devices";
//
// command fetch to use cookies https://github.com/JakeChampion/fetch#sending-cookies
//const fetcher = (url) => fetch(url).then( (res) => res.json()).then( json => console.log('JSON: ', json) );
//const fetcher = (...args) => fetch(...args, { credentials: "same-origin" } ).then( res => res.json() );
const fetcher = (...args) => fetch(...args, { credentials: "include" } ).then( res => res.json() );

const { BaseLayer } = LayersControl;

//////////// FUNCTIONS ///////////////////////////////////////

function LoginEstonia( ) {
	const authEE = 'https://id.netnix.ee/auth/ee/';
	var { data, error, isLoading } = useSWR( authEE, fetcher);
}

function ChangeView( center, zoom) {
	console.log('CENTER', center);
	const map = useMap();
	map.setView([59.5171700, 24.8494433], map.getZoom());
	return null;

}

function geoLockDevice( device ) {
	console.log(`Device ${device.name} geolocked.` );
	return true;
}

function immobilizeDevice( device ) {
	console.log(`Device ${device.name} immobilized.` );
	return true;
}

export default function App() {
	// Hooks
	const [ activeDevice, setActiveDevice ] = useState(null);
	const [ map, setMap ] = useState(null);
	const [ position, setPosition ] = useState(null);

	useEffect( () => {
		if (!map) return;

		console.log('useEffect()');
		//if (map) alert('Yes map!');
		//if (!map) { alert("No map! Can't add buttons"); return; }
		map._fadeAnimated = true;
		map._zoomAnimation = true;
		//setTimeout(L.bind(this._onZoomTransitionEnd, this), 250);

		console.log('Adding button');
		L.easyButton(
			'<span class="target"> &target;</span>', 
			() => {
					map.locate().on(
						'<span class="target"> &target;</span>', 
						function (e) {
							setPosition(e.latlng);
							//map.flyTo(e.latlng, map.getZoom(), { animate: true, duration: 3.5 } );
							map.flyTo(e.latlng, 13, { animate: true, duration: 3.5 } );
						}
					);
				}
		).addTo(map);
	}, [map]);

	const { data, error, isLoading } = useSWR( api, fetcher);
	if (error) console.log("An error has occurred.");
	if (isLoading) {
		return <div>Loading devices</div>
	}
	console.log('useSWR returns: ', data, error, isLoading);
	console.log('Data: ', data)

	var devices = data && !error ? data : [];
	//devices = [ { "uuid": "dc8ac873-0854-40db-a122-ebd1dc2c50d4", "location": { "latitude": "59.5171700", "longitude": "24.8494433" } } ];
	console.log('Devices: ',  devices);

	if ( devices.length > 0 ) {
		var center = [ devices[0].location.latitude, devices[0].location.longitude ];
	} else {
		var center = [24.8494433, 59.5171700];
	}
	const styleImg = { width: '40px', };
	//const imageUrl = 'http://172.16.21.90:3000/%s.jpg' + device.name + 'jpg';

	const styles = StyleSheet.create({
	  container: {
		flex: 1,
		justifyContent: 'center',
	  },
	  text: {
		fontSize: 16,
	  },
	  wrapperCustom: {
		borderRadius: 8,
		padding: 6,
	  },
	  logBox: {
		padding: 20,
		margin: 10,
		borderWidth: StyleSheet.hairlineWidth,
		borderColor: '#f0f0f0',
		backgroundColor: '#f9f9f9',
	  },
	});



	////////////// UI ELEMENTS /////////////////////////////////7


	// this.ChangeView = this.ChangeView.bind(this);

	function DeviceList( {devices} ) {
		const mapInstance = useMap()
		const listItems = devices.map( device => 
			<li key={device.uuid} >
				<Pressable 
					const center = { [ Number(device.location.latitude), Number(device.location.longitude) ] }
					onPress={ () => ChangeView() } 
				>
					<Text>{device.name}</Text>
				</Pressable>
			</li>
		);
		return <div id="deviceList"><ul>{listItems}</ul></div>;
	}

	function DeviceImage( {device} ) {
		return (
		<img 
			style={{width: '100px',}} 
			src={ `/${device.name}.jpg` }
		/>
		);
	}


	function LoginWindow( ) {
		const [show, setShow] = useState(false);
		const handleLoginEstonia = () => LoginEstonia(); 
		const handleClose = () => setShow(false);
		const handleShow = () => setShow(true);

		return (
			<div id='login' >
				<Button
					style={{ zIndex: 1000 }} 
					onClick={ handleShow }>
				Login</Button>

				<Modal.Dialog
					open={show}
					onHide={handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Authentication</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						Flags go here.
					</Modal.Body>

					<Modal.Footer>
						<Pressable onPress={handleLoginEstonia}><Text>Estonia</Text></Pressable>
					</Modal.Footer>
				</Modal.Dialog>
			</div>
		);
	}

	function LockButton( {device}) {
		<Pressable 
			onPress={ () => geoLockDevice(device) } 
			style={({pressed}) => [  
					{
						backgroundColor: pressed ? 'rgb(210, 230, 255)' : 'lightgreen',
					 },
					styles.wrapperCustom 
			]}
		>
		{({pressed}) => (
			  <Text style={styles.text}>{pressed ? 'Pressed!' : 'Geo Lock'}</Text>
			)}
		</Pressable>
	}

	function ImmobilizeButton({device}) {
		<Pressable
			onPress={ () => immobilizeDevice(device) } 
		>
			<Text style="">Immobilize</Text>
		</Pressable>

	}





	/////////// MAP /////////////////////////////////////////////
	return (
		<MapContainer 
			center={center} 
			zoom={15} 
			style={{ height: "100vh"}}
			ref={ setMap }  >

			<LayersControl collapsed={true}>
				<BaseLayer checked name="OpenStreetMap">
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
						contributors'
					/>
				<ChangeView center={center} zoom={15} />
				<LoginWindow />
				<DeviceList devices={devices} />
				</BaseLayer>
			</LayersControl>


			{ devices.map( 
				device => (
					//{{  imageUrl = 'http://172.16.21.90:3000/%s.jpg' + device.name + 'jpg' }}
					<Marker key={ device.uuid } position={ [device.location.latitude, device.location.longitude] } >
						<Popup>
							<h2>{ device.name }</h2>
							<DeviceImage device={ device } />
							<div>
							Location: { device.location.latitude }, { device.location.longitude } <br/>
							Last seen: { device.lastseen }<br/> 
							Battery: {device.battlvl } %
								<View style={styles.container}>
									<LockButton device={device} />
									<View style={styles.logBox}>
										<ImmobilizeButton device={device} />
									</View>
								</View>
							</div>
						</Popup>
					</Marker>
				)
			)}
		</MapContainer>
	);
}

// vim: sw=4 ts=4 
